import { Component } from 'preact'
import debounce from 'debounce'
import { connect } from 'preact-redux'
import cx from 'classnames'
import hideIosKeyboard from 'hideIosKeyboard'
import reachGoal from 'lib/reachGoal'

import fetchProductPricing from 'lib/products/fetchPricing'

import {
  setHidden,
  setVisible,
  setVisibleGift,
  setUser,
  setUserEmailNotice,
  setPaymentFailed,
  setSubscriptionUpdateFailed,
  setScreenSuccess,
  setLoading,
  setBasementLoading,
  revertPreviousUserState,
  removeDirtyForm,
  setUpdatedSubscription,
  setSubscriptionCancellationFailed,
  setSubscriptionCancellationRevertFailed,
  setCancelledSubscription,
  revertCancelledSubscription,
  setUpdatedPricing,
  closeBasement,
} from './newPaybarActions'

import { detectPaybarInner } from './variants'
import { setTypeToUrl, removeTypeFromUrl } from './typesUrlHelper'
import isSubscribed from './isSubscribed'

import StickyButton from '../stickyButton/stickyButton'
import NewPaybarOverlay from './newPaybarOverlay'
import CrossButton from '../crossButton/crossButton'
import NewPaybarFooter from './newPaybarFooter'
import NewPaybarNotification from './newPaybarNotification'
import NewPaybarHiddenImages from './newPaybarHiddenImages'
import expandScreenToFlags from './expandScreenToFlags'
import isNoticeTypeVisible from './isNoticeTypeVisible'
import isLaunchpad from './isLaunchpad'

import checkOrCreateUser from 'products/checkOrCreateUser'
import subscriptionActions from 'products/subscriptionActions'
import Purchase from 'products/purchase'
import ProductUser from 'products/productUser'
import GiftButton from '../giftButton/giftButton'
import shouldFetchPricingAfterSubscription from './helpers/shouldFetchPricingAfterSubscription'
import NewPaybarBasement from './newPaybarBasement'
import isSubscriptionCancelled from './isSubscriptionCancelled'
import NewPaybarStyles from './newPaybarStyles'
import { isOnMobile } from 'lib/envHelpers'
import FormFieldsStorage from './formFieldsStorage'
import fetchLicense from './helpers/fetchLicense'

const PAYER_MARGIN_TOP = 18
const MIN_DESKTOP_WIDTH = 960

const LICENSE_PARAM_NAME = 'license'
const NEW_PAYBAR_OPTIONS = window.NEW_PAYBAR_OPTIONS

const extractMeta = () => (window.location.search + '').replace(/^\?/, '')

class NewPaybar extends Component {
  constructor(props) {
    super(props)

    window.application.newPaybar = window.application.newPaybar || {}

    this.state = {
      shouldDisableScroll: false,
      totalHeight: 0,
      boxHeight: 0,
      notificationHeight: 0,
      basementHeight: 0,
      licenseContents: '',
    }
    this.touchStartY = 0

    this.formFieldsStorage = new FormFieldsStorage()

    this.onEscape = this.onEscape.bind(this)
    this.onWheel = this.onWheel.bind(this)
    this.onTouchStart = this.onTouchStart.bind(this)
    this.onTouchMove = this.onTouchMove.bind(this)
    this.onEmailFormSubmit = this.onEmailFormSubmit.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onSubscriptionUpdate = this.onSubscriptionUpdate.bind(this)
    this.onSubscriptionCancel = this.onSubscriptionCancel.bind(this)
    this.onSubscriptionCancellationRevert = this.onSubscriptionCancellationRevert.bind(this)
    this.onBasementClose = this.onBasementClose.bind(this)

    this.setScrollingAvailability = debounce(50, this.setScrollingAvailability.bind(this))
  }

  componentWillMount() {
    this.installAdditionalStyles()
  }

  componentDidMount() {
    document.body.addEventListener('keydown', this.onEscape)
    document.body.addEventListener('wheel', this.onWheel, { passive: false })
    $(document).on('appResize', this.setHeights.bind(this))

    if (!isOnMobile()) {
      document.body.addEventListener('touchmove', this.onTouchMove, { passive: false })
      document.body.addEventListener('touchstart', this.onTouchStart, { passive: false })
    }

    if (this.props.isVisible) this.show()

    this.setScrollingAvailability()
    this.setScrollingOnBody()
    this.setHeights()
    this.fetchAndRenderLicense()
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.onEscape)
    document.body.removeEventListener('wheel', this.onWheel)
    document.body.removeEventListener('touchmove', this.onTouchMove)
    document.body.removeEventListener('touchstart', this.onTouchStart)
    $(document).off('appResize')
  }

  componentDidUpdate(prevProps) {
    this.setScrollingAvailability()
    this.setScrollingOnBody()

    if (this.props.screen === 'purchase') {
      const shouldScrollBodyToTheTop = this.isOnMobile &&
        (this.props.screen !== prevProps.screen || this.props.isVisible !== prevProps.isVisible)

      if (shouldScrollBodyToTheTop) document.body.scrollTop = 0

      if (iNoBounce.isEnabled()) iNoBounce.disable()
    } else if (this.props.isVisible && !iNoBounce.isEnabled() && this.isOnIOS) {
      iNoBounce.enable()
    }

    if (!prevProps.isVisible && this.props.isVisible) {
      this.show()
      setTypeToUrl(this.props.type)
    }

    if (prevProps.isVisible && !this.props.isVisible) {
      this.hide()
      removeTypeFromUrl()
    }

    if (prevProps.isInitialScreen &&
        this.props.isPurchaseScreen &&
        this.isOnMobile) {
      this.scrollFormToBottom()
    }

    const shouldSetHeight = prevProps.noticeType !== this.props.noticeType ||
      prevProps.activeBasementItem !== this.props.activeBasementItem ||
      this.notificationHeight !== this.state.notificationHeight

    if (shouldSetHeight) this.setHeights()

    this.checkLicenseParam(prevProps)

    if (prevProps.type !== this.props.type) setTypeToUrl(this.props.type)
  }

  checkLicenseParam(prevProps) {
    const hadLicenseItem = prevProps.activeBasementItem === LICENSE_PARAM_NAME
    const hasLicenseItem = this.props.activeBasementItem === LICENSE_PARAM_NAME

    if (!hadLicenseItem && hasLicenseItem) setTypeToUrl(LICENSE_PARAM_NAME)
    if (hadLicenseItem && !hasLicenseItem) {
      removeTypeFromUrl(LICENSE_PARAM_NAME)
      setTypeToUrl(this.props.type)
    }
  }

  setScrollingAvailability() {
    const { shouldDisableScroll } = this

    if (this.state.shouldDisableScroll !== shouldDisableScroll) {
      this.setState({ shouldDisableScroll })
    }
  }

  setScrollingOnBody() {
    if (this.state.shouldDisableScroll) {
      document.body.classList.add('has__overlay')
    } else {
      document.body.classList.remove('has__overlay')
    }
  }

  setHeights() {
    const newState = {
      ...this.state,
      totalHeight: this.totalHeight,
      boxHeight: this.boxHeight,
      notificationHeight: this.notificationHeight,
      basementHeight: this.basementHeight,
    }

    if (newState === this.state) return

    this.setState(newState)
  }

  show() {
    this.preservedScrollPosition = window.application.scrollTop

    if (this.isOnMobile) window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
    document.body.classList.add('has__paybar')

    if (this.scrollable) this.scrollable.scrollTop = 0
    if (this.props.isInitialScreen && !this.isOnMobile && !this.isOnIOS) {
      this.emailFormInput.select()
    }

    $(document).trigger('appResize')
  }

  hide() {
    document.body.classList.remove('has__paybar')

    if (iNoBounce.isEnabled()) iNoBounce.disable()

    if (window.application.scrollTop !== this.preservedScrollPosition && this.isOnMobile) {
      setTimeout(() => {
        window.scrollTo({ top: this.preservedScrollPosition, left: 0, behavior: 'instant' })
      }, 100) // HACK: otherwise Safari still doesn't apply layout and keep scroll at 0,0
    }
  }

  onTouchStart(e) {
    if (!e.changedTouches || !this.state.shouldDisableScroll) return

    this.touchStartY = e.changedTouches[0].clientY
  }

  onTouchMove(e) {
    if (!e.changedTouches || !this.state.shouldDisableScroll) return

    e.preventDefault()
    const currentTouchY = e.changedTouches[0].clientY
    const delta = this.touchStartY - currentTouchY

    this.passScroll(delta)

    this.touchStartY = currentTouchY
  }

  onEscape({ keyCode }) {
    if (keyCode === 27) this.props.setHidden()
  }

  onWheel(e) {
    if (!this.state.shouldDisableScroll) return

    if (this.emittedByScrollableElement(e)) return

    e.preventDefault()
    this.passWheelEvent(e)
  }

  emittedByScrollableElement(e) {
    const scrollableElements = ['TEXTAREA']
    return (e.target && scrollableElements.includes(e.target.nodeName))
  }

  passWheelEvent(e) {
    this.passScroll(e.deltaY)
  }

  passScroll(amount) {
    let target = this.scrollable
    if (isLaunchpad(this.props)) target = this.overlay
    if (this.hasOverallScroll) target = this.basement

    if (!target) return

    target.scrollTop += amount
  }

  scrollFormToBottom() {
    document.body.scrollTop = this.payerEl.offsetTop - PAYER_MARGIN_TOP
  }

  onEmailFormSubmit({ email }) {
    const { productId, coupon } = this.props
    const meta = extractMeta()
    const subscriptionType = this.props.type
    const currentUser = window.application.user

    if (currentUser && currentUser.email === email) {
      this.props.revertPreviousUserState()
      return Promise.resolve({ email })
    }

    return checkOrCreateUser({ email, productId, subscriptionType, coupon, meta })
      .then(json => this.setUserOrEmailNotice({ email, ...json }))
  }

  onSubmit() {
    hideIosKeyboard()
    this.props.setLoading()

    const shouldFetchPricing = shouldFetchPricingAfterSubscription(this.props)

    return subscriptionActions.create()
      .then(json => {
        this.props.setScreenSuccess(json)
        this.rememberPurchase(json)
        this.trackPurchase()
        this.clearRememberedFields()
        this.props.removeDirtyForm()

        if (shouldFetchPricing) this.fetchPricing(json)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e)

        return this.props.setPaymentFailed(e)
      })
  }

  onSubscriptionUpdate() {
    hideIosKeyboard()
    this.props.setLoading()

    return subscriptionActions.update()
      .then(json => {
        this.props.setUpdatedSubscription(json)
        this.props.removeDirtyForm()
      })
      .catch(this.props.setSubscriptionUpdateFailed)
  }

  onSubscriptionCancel() {
    hideIosKeyboard()
    this.props.setBasementLoading()

    const { productId, subscription } = this.props
    const { id: subscriptionId } = subscription

    return subscriptionActions.cancel({ productId, subscriptionId })
      .then(json => {
        this.props.setCancelledSubscription(json)
        this.fetchPricing({ subscription })
      })
      .catch(this.props.setSubscriptionCancellationFailed)
  }

  onSubscriptionCancellationRevert() {
    hideIosKeyboard()
    this.props.setBasementLoading()

    const { productId, subscription, lastSubscription } = this.props

    return subscriptionActions.revert({ productId, token: subscription.retention_token_code })
      .then(json => {
        this.props.revertCancelledSubscription(json)
        this.fetchPricing({ subscription: lastSubscription })
      })
      .catch(this.props.setSubscriptionCancellationRevertFailed)
  }

  onBasementClose() {
    const shouldHidePaybar = isOnMobile() &&
      !isSubscriptionCancelled(this.props.subscription) &&
      this.props.isSubscriptionScreen

    if (shouldHidePaybar) this.props.setHidden()

    this.props.closeBasement()
  }

  setUserOrEmailNotice({ accessToken, notice, email, cardIframeUrl }) {
    if (accessToken) {
      this.forgetPurchase()
      this.forgetUser()
        .then(() => this.props.setUser({ email, accessToken, cardIframeUrl }))
        .catch(() => {
          throw Error('Cannot logout current user')
        })
    } else {
      this.props.setUserEmailNotice({ notice, email })
      this.emailFormInput.focus()
    }
  }

  rememberPurchase({ type }) {
    if (this.props.isUserAuthenticated || type !== 'self') return

    (new Purchase(this.props)).rememberUnconfirmed()
  }

  trackPurchase() {
    reachGoal(this.purchaseGoal)
    reachGoal(this.broadGoal)
  }

  forgetPurchase() {
    return (new Purchase(this.props)).forget()
  }

  forgetUser() {
    return (new ProductUser()).logout()
  }

  installAdditionalStyles() {
    NewPaybarStyles.install()
  }

  clearRememberedFields() {
    this.formFieldsStorage.clear()
  }

  fetchPricing({ subscription }) {
    if (!subscription) return

    fetchProductPricing(subscription.product_id)
      .then(this.props.setUpdatedPricing)
  }

  get purchaseGoal() {
    const suffix = (this.props.type === 'gift') ? '-AS-GIFT' : ''

    return `${this.props.goalPrefix}_PAYBAR_${this.props.paybarGoalVersion}BOUGHT${suffix}`
  }

  get broadGoal() {
    return `${this.props.goalPrefix}_BOUGHT`
  }

  get stickyButtonGoal() {
    return `${this.props.goalPrefix}_PROMO_${this.props.paybarGoalVersion}PRE`
  }

  get stickyButtonType() {
    if (this.props.prices.specialOffer) {
      return 'upgrade'
    }

    if (this.props.isPreorder) {
      return 'preorder'
    }

    return 'purchase'
  }

  get subscriptionUrl() {
    const { product, productId } = this.props
    const isLecture = ['lecture', 'collecture'].includes(product)
    const isCourse = product === 'course'

    let scope = 'projects'
    if (isLecture) scope = 'lectures'
    if (isCourse) scope = 'courses'

    return `/${scope}/${productId}/subscription/`
  }

  get buyButtonText() {
    let type = 'default'

    if (this.props.isPreorder) type = 'preorder'
    if (this.props.prices.specialOffer) type = 'upgrade'
    if (this.props.isSubscribed) type = 'subscribed'
    if (this.props.isSubscribed && this.props.isPreorder) type = 'subscribedPreorder'

    return I18n.t(`newPaybar.buyButtonText.${this.props.product}.${type}`)
  }

  get buttonCountdown() {
    const { subscription } = this.props

    if (!isSubscriptionCancelled(subscription)) return false

    return subscription.retention_token_valid_until
  }

  get shouldDisableScroll() {
    if (!this.props.isVisible) return false

    if (this.isOnMobile) return this.props.isVisible

    return this.props.shouldDisableScroll || this.props.hasFormContent || this.hasOverallScroll
  }

  get isOnMobile() {
    return window.innerWidth < MIN_DESKTOP_WIDTH
  }

  get isOnIOS() {
    return window.application.isOnIOS
  }

  get notificationHeight() {
    if (!this.notification) return 0

    return this.notification.offsetHeight
  }

  get boxHeight() {
    if (!this.boxIn) return 0

    return this.boxIn.offsetHeight
  }

  get basementHeight() {
    if (!this.basement) return 0

    return this.basement.offsetHeight
  }

  get totalHeight() {
    return this.boxHeight + this.basementHeight + this.notificationHeight
  }

  get hasOverallScroll() {
    return this.props.activeBasementItem === 'license'
  }

  get buttonBadge() {
    return NEW_PAYBAR_OPTIONS.buttonBadge
  }

  fetchAndRenderLicense() {
    fetchLicense(this.props.product)
      .then(body => this.renderLicense(body))
      .catch(e => console.error(e))
  }

  renderLicense(body) {
    this.setState({ licenseContents: body })
  }

  render(props) {
    const hasActiveBasement = !!props.activeBasementItem

    const mods = (NEW_PAYBAR_OPTIONS.mod || '')
      .split(' ')
      .map(mod => `is__${mod}`)
      .join(' ')

    const newPaybarClassNames = cx('newPaybar',
      `is__${props.product}`,
      `is__${props.screen}`,
      `is__${props.type}`,
      `is__${props.productId}`,
      mods,
      {
        is__visible: props.isVisible,
        is__loading: props.isLoading || props.isBasementLoading,
        is__basementLoading: props.isBasementLoading,
        has__notice: isNoticeTypeVisible(props),
        is__preorder: props.isPreorder,
        is__userAuthenticated: props.isUserAuthenticated,
        is__launchpad: isLaunchpad(props),
        is__upgradeAvailable: props.prices.specialOffer,
        has__noScroll: this.state.shouldDisableScroll,
        has__overallScroll: this.hasOverallScroll,
        is__subscriptionCancelled: isSubscriptionCancelled(props.subscription),
        has__activeBasement: hasActiveBasement,
      })

    const newPaybarStyles = `
      --newPaybarBoxHeight: ${this.state.boxHeight}px;
      --newPaybarNotificationHeight: ${this.state.notificationHeight}px;`

    const NewPaybarProduct = detectPaybarInner(props.product)

    return (
      <section className={ newPaybarClassNames }
        style={ newPaybarStyles }
        ref={ el => this.el = el }>
        <StickyButton
          onClick={ props.setVisible }
          text={ this.buyButtonText }
          countdown={ this.buttonCountdown }
          goal={ this.stickyButtonGoal }
          type={ this.stickyButtonType }
          badge={ this.buttonBadge }
        >
          { !props.hasCompanyTabOnly &&
            <GiftButton
              onClick={ props.setVisibleGift}
              goal={ this.stickyButtonGoal }
              type={ this.stickyButtonType }
            />
          }
        </StickyButton>

        <div className="newPaybar-box" ref={ el => this.box = el }>
          { props.noticeType && !props.isSuccessScreen &&
            <NewPaybarNotification device="desktop laptop" getRef={ el => this.notification = el }/>
          }

          <div className="newPaybar-boxIn" ref={ el => this.boxIn = el }>
            <div className="newPaybar-wrapper">
              <div className="newPaybar-wrapperIn">
                <div className="newPaybar-info">
                  <NewPaybarProduct
                    subscriptionUrl={ this.subscriptionUrl }
                    scrollableRef={ el => this.scrollable = el }
                    pricingBottomCaption={ props.pricingBottomCaption }
                    requirements={ props.requirements }
                    payerRef={ el => this.payerEl = el }>
                    <CrossButton device="mobile" onClick={ props.setHidden } />
                  </NewPaybarProduct>
                </div>
              </div>

              <NewPaybarFooter
                onSubmit={ this.onSubmit }
                onEmailFormSubmit={ this.onEmailFormSubmit }
                onSubscriptionUpdate={ this.onSubscriptionUpdate }
                emailFormInputRef={ el => this.emailFormInput = el }
                isLicenseLoaded={ !!this.state.licenseContents } />
            </div>
          </div>

          <NewPaybarBasement
            getRef={ $el => this.basement = $el }
            isLoading={ this.props.isLoading }
            subscription={ this.props.subscription }
            canDisplaySubscriptionInfo={ !this.props.isInitialScreen }
            product={ this.props.product }
            productTitle={ this.props.productTitle }
            isPreorder={ this.props.isPreorder }
            activeItem={ this.props.activeBasementItem }
            onSubscriptionCancel={ this.onSubscriptionCancel }
            onSubscriptionCancellationRevert={ this.onSubscriptionCancellationRevert }
            onKeep={ this.props.setHidden }
            onClose={ this.onBasementClose }
            licenseContents={ this.state.licenseContents }/>
        </div>

        <NewPaybarOverlay className="device device__desktop device__laptop" overlayRef={ el => this.overlay = el }/>

        <NewPaybarHiddenImages books={ props.books } product={ props.product } />
      </section>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  const isSuccessScreen = newPaybar.screen === 'success'
  const isLaunchpadScreen = newPaybar.screen === 'subscription'
  const shouldDisableScroll = isSuccessScreen || isLaunchpadScreen

  return {
    isVisible: newPaybar.isVisible,
    isLoading: newPaybar.isLoading,
    isBasementLoading: newPaybar.isBasementLoading,
    shouldDisableScroll: shouldDisableScroll,
    type: newPaybar.type,
    product: newPaybar.product,
    productTitle: newPaybar.productTitle,
    isPreorder: newPaybar.isPreorder,
    screen: newPaybar.screen,
    activeBasementItem: newPaybar.activeBasementItem,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    paybarGoalVersion: newPaybar.paybarGoalVersion,
    userEmail: newPaybar.userEmail,
    accessToken: newPaybar.accessToken,
    userEmailNotice: newPaybar.userEmailNotice,
    subscription: newPaybar.subscription,
    lastSubscription: newPaybar.lastSubscription,
    productId: newPaybar.subscription.product_id,
    goalPrefix: newPaybar.goalPrefix,
    noticeType: newPaybar.noticeType,
    disclaimer: newPaybar.disclaimer,
    books: newPaybar.shelfBooks,
    hasSubscribedSelf: newPaybar.hasSubscribedSelf,
    isSubscribed: isSubscribed(newPaybar),
    prices: newPaybar.prices,
    coupon: newPaybar.prices.coupon,
    hasFormContent: newPaybar.hasFormContent,
    hasCompanyTabOnly: newPaybar.hasCompanyTabOnly,
    pricingBottomCaption: newPaybar.pricingBottomCaption,
    requirements: newPaybar.requirements,
    productDetails: newPaybar.productDetails,
    ...expandScreenToFlags(newPaybar.screen),
  }
}

const mapDispatchToProps = {
  setHidden,
  setVisible,
  setVisibleGift,
  setUser,
  setUserEmailNotice,
  setPaymentFailed,
  setSubscriptionUpdateFailed,
  setSubscriptionCancellationFailed,
  setSubscriptionCancellationRevertFailed,
  setScreenSuccess,
  setLoading,
  setBasementLoading,
  revertPreviousUserState,
  removeDirtyForm,
  setUpdatedSubscription,
  setCancelledSubscription,
  revertCancelledSubscription,
  setUpdatedPricing,
  closeBasement,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPaybar)
