import { Component } from 'preact'
import cx from 'classnames'

import { birmanize, formatNumber } from 'lib/textHelper'

import { schoolEnrollDeadlineScoreSpacer as config } from './schoolSpacersConfig'
import { fetchSchoolData, getSchoolLink } from './schoolSpacerUtils'
import { moscowifyLocal } from '../calendar/timezoneUtils'

import { TextNode, Caption } from '../textNodes'
import LinkWithArrow from '../linkWithArrow'
import SchoolSpacerTitle from './schoolSpacerTitle'
import SchoolSpacerIcon from './schoolSpacerIcon'
import SchoolSpacerEnrollButton from './schoolSpacerEnrollButton'
import { DeadlineScoreStickerIcon } from '../icons'

export default class SchoolEnrollDeadlineScoreSpacer extends Component {
  constructor(props) {
    super(props)

    this.state = { currentScore: { score: '', endsAt: '' }, enrollDate: '' }

    this.isFull = props.size === 'full'
    this.schoolLink = getSchoolLink(props.schoolKey)
  }

  componentDidMount() {
    fetchSchoolData()
      .then(json => this.setState({ ...json }))
      .catch(e => console.error(e))
  }

  get isX2() {
    return this.props.classes.includes('is__x2')
  }

  render(props) {
    if (!this.state.enrollDate) return null

    const moscowTime = moscowifyLocal(new Date(this.state.currentScore.endsAt))
    const date = props.endsAt || birmanize(moscowTime, { format: 'dateOnly' })
    const score = props.score || formatNumber(this.state.currentScore.score)
    const scoreParts = score.split(',') || []

    const classes = cx(
      'schoolEnrollDeadlineScoreSpacer',
      props.classes,
      {
        is__full: this.isFull,
        [`is__${props.schoolKey}`]: props.schoolKey,
      }
    )

    return (
      <div className={ classes }>
        <div className="device laptop desktop">
          <div className="schoolEnrollDeadlineScoreSpacer-cols">
            <div className="schoolEnrollDeadlineScoreSpacer-countdownWrapper">
              <a href={ this.schoolLink } className="schoolEnrollDeadlineScoreSpacer-countdown novisited doubleHover is__noUnderline">
                <DeadlineScoreStickerIcon />
                { score && date &&
                  <div className="schoolEnrollDeadlineScoreSpacer-countdownScore">
                    { scoreParts[0] }
                    <span>,</span>
                    { scoreParts[1] || '0' }
                  </div>
                }
                { score && date &&
                  <Caption className="schoolEnrollDeadlineScoreSpacer-countdownDays">
                    балла за срок<br />до { date }
                  </Caption>
                }
              </a>
            </div>

            <div className="schoolEnrollDeadlineScoreSpacer-text">
              <SchoolSpacerTitle
                href={ this.schoolLink }
                title={ config.title[props.schoolKey] }
                isX2={ this.isX2 }
              />
              <div className="schoolEnrollDeadlineScoreSpacer-undertitle">
                <div className="schoolEnrollDeadlineScoreSpacer-about">
                  <TextNode>
                    <b>В пн станет дороже.</b> Раньше учёба — ниже стоимость <br className="device laptop" />
                    и выше шанс на бесплатное место. <br class="device desktop" /><LinkWithArrow
                      href={ this.schoolLink }
                      text="Программа"
                      isInline
                      isDoubleHover />
                  </TextNode>
                </div>
                <SchoolSpacerEnrollButton schoolLink={ this.schoolLink } isGift={ props.isGift } />
              </div>
            </div>
          </div>
        </div>

        <div className="device mobile">
          <div className="schoolEnrollDeadlineScoreSpacer-cols">
            <SchoolSpacerIcon schoolKey={ props.schoolKey } href={ this.schoolLink } />
            <SchoolSpacerTitle
              href={ this.schoolLink }
              title={ config.title[props.schoolKey] }
              isX2={ this.isX2 }
            />
          </div>
          <TextNode>
            Раньше учёба — ниже стоимость и выше шанс на бесплатное место. <LinkWithArrow
              href={ this.schoolLink }
              text="Программа"
              isInline />
          </TextNode>
          <div className="schoolEnrollDeadlineScoreSpacer-mobileButtons">
            <div className="schoolEnrollDeadlineScoreSpacer-countdown">
              <div className="schoolEnrollDeadlineScoreSpacer-countdownScore">
                { scoreParts[0] },{ scoreParts[1] || '0' } балла
              </div>
              <Caption className="schoolEnrollDeadlineScoreSpacer-countdownDays">
                до { date }
              </Caption>
            </div>
            <SchoolSpacerEnrollButton schoolLink={ this.schoolLink } isGift={ props.isGift } />
          </div>
        </div>
      </div>
    )
  }
}
