import { Component } from 'preact'
import cx from 'classnames'
import { formatPrice } from 'lib/textHelper'

import { schoolEnrollOpenSpacer as config } from '../schoolSpacersConfig'
import { fetchSchoolData, getSchoolLink } from '../schoolSpacerUtils'

import { TextNode } from '../../textNodes'
import LinkWithArrow from '../../linkWithArrow'
import SchoolSpacerTitle from '../schoolSpacerTitle'
import SchoolSpacerIcon from '../schoolSpacerIcon'
import SchoolSpacerEnrollButton from '../schoolSpacerEnrollButton'

const ICONS_MAP = {
  designers: 'designersFunicularIcon',
  editors: 'editorsEscalatorIcon',
  managers: 'managersCartIcon',
}

export default class SchoolEnrollOpenSpacer extends Component {
  constructor(props) {
    super(props)

    this.state = { enrollDate: '' }

    this.isFull = props.size === 'full'
    this.schoolLink = getSchoolLink(props.schoolKey)
  }

  componentDidMount() {
    fetchSchoolData()
      .then(json => this.setState({ ...json }))
      .catch(e => console.error(e))
  }

  get currentPrice() {
    return this.state.currentPrice ? formatPrice(this.state.currentPrice.total) : '65 000'
  }

  get maxPrice() {
    return this.state.currentPrice ? formatPrice(this.state.currentPrice.max) : '87 000'
  }

  get isX2() {
    return this.props.classes.includes('is__x2')
  }

  render(props) {
    const classes = cx(
      'schoolEnrollOpenSpacer',
      props.classes,
      {
        is__full: this.isFull,
        [`is__${props.schoolKey}`]: props.schoolKey,
      }
    )

    return (
      <div className={ classes }>
        <div className="device laptop desktop">
          <div className="schoolEnrollOpenSpacer-cols">
            <SchoolSpacerIcon iconKey={ ICONS_MAP[props.schoolKey] } href={ this.schoolLink } />
            <div className="schoolEnrollOpenSpacer-text">
              <SchoolSpacerTitle
                href={ this.schoolLink }
                title={ config.title[props.schoolKey] }
                isX2={ this.isX2 }
              />
              <div className="schoolEnrollOpenSpacer-undertitle">
                <div className="schoolEnrollOpenSpacer-about">
                  <TextNode>
                    До воскресенья оценка за срок максимальная, а стоимость минимальная: <s>{this.maxPrice}</s> <nobr>{this.currentPrice} ₽</nobr>. <br className="device desktop" />Начало в любой день. 
                    <LinkWithArrow
                      href={ this.schoolLink }
                      text="Программа"
                      isInline isDoubleHover />
                  </TextNode>
                </div>
                <SchoolSpacerEnrollButton schoolLink={ this.schoolLink } isGift={ props.isGift } />
              </div>
            </div>
          </div>
        </div>

        <div className="device mobile">
          <div className="schoolEnrollOpenSpacer-cols">
            <SchoolSpacerIcon iconKey={ ICONS_MAP[props.schoolKey] } href={ this.schoolLink } />
            <SchoolSpacerTitle
              href={ this.schoolLink }
              title={ config.title[props.schoolKey] }
              isX2={ this.isX2 }
            />
          </div>
          <TextNode>
            До воскресенья оценка за срок максимальная, а стоимость минимальная: <s>{this.maxPrice}</s> <nobr>{this.currentPrice} ₽</nobr>.
          </TextNode>
          <div className="schoolEnrollOpenSpacer-mobileButtons">
            <div>
              <TextNode>
                <LinkWithArrow href={ this.schoolLink } text="Программа" isInline />
              </TextNode>
            </div>
            <SchoolSpacerEnrollButton schoolLink={ this.schoolLink } isGift={ props.isGift } />
          </div>
        </div>
      </div>
    )
  }
}
