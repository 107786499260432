import { Component } from 'preact'
import cx from 'classnames'

import { schoolEnrollEndingSpacer as config } from './schoolSpacersConfig'
import { fetchSchoolData, getSchoolLink } from './schoolSpacerUtils'

import { TextNode } from '../textNodes'
import LinkWithArrow from '../linkWithArrow'
import SchoolSpacerTitle from './schoolSpacerTitle'
import SchoolSpacerIcon from './schoolSpacerIcon'
import SchoolSpacerEnrollButton from './schoolSpacerEnrollButton'
import { Countdown, CountdownMobile } from '../countdown'

const ICONS_MAP = {
  designers: 'designersFunicularIcon',
  editors: 'editorsEscalatorIcon',
  managers: 'managersCartIcon',
}

export default class SchoolEnrollEndingSpacer extends Component {
  constructor(props) {
    super(props)

    this.state = { enrollDate: '', noReturnDate: '' }

    this.isFull = props.size === 'full'
    this.schoolLink = getSchoolLink(props.schoolKey)
  }

  componentDidMount() {
    fetchSchoolData()
      .then(json => this.setState({ ...json }))
      .catch(e => console.error(e))
  }

  get isX2() {
    return this.props.classes.includes('is__x2')
  }

  render(props) {
    const classes = cx(
      'schoolEnrollEndingSpacer',
      props.classes,
      {
        is__full: this.isFull,
        [`is__${props.schoolKey}`]: props.schoolKey,
      }
    )

    return (
      <div className={ classes }>
        <div className="device laptop desktop">
          <div className="schoolEnrollEndingSpacer-cols">
            <a href={ this.schoolLink } className="schoolEnrollEndingSpacer-countdown novisited doubleHover is__noUnderline">
              { this.state.noReturnDate &&
                <Countdown enrollEndDate={ this.state.noReturnDate } />
              }
            </a>
            <div className="schoolEnrollEndingSpacer-text textBox">
              <SchoolSpacerTitle
                href={ this.schoolLink }
                title={ config.title[props.schoolKey] }
                isX2={ this.isX2 }
              />
              <div className="schoolEnrollEndingSpacer-undertitle">
                <div className="schoolEnrollEndingSpacer-about">
                  <TextNode>
                    { config.text }<br className="device desktop" /> Начало занятий в любой день. 
                    <LinkWithArrow href={ this.schoolLink } text="Программа" isInline isDoubleHover />
                  </TextNode>
                </div>
                <SchoolSpacerEnrollButton schoolLink={ this.schoolLink } isGift={ props.isGift } />
              </div>
            </div>
          </div>
        </div>

        <div className="device mobile">
          <div className="schoolEnrollEndingSpacer-cols">
            <SchoolSpacerIcon iconKey={ ICONS_MAP[props.schoolKey] } href={ this.schoolLink } />
            <SchoolSpacerTitle
              href={ this.schoolLink }
              title={ config.title[props.schoolKey] }
              isX2={ this.isX2 }
            />
          </div>
          <TextNode>
            { config.text } Начало занятий в любой день. <LinkWithArrow href={ this.schoolLink } text="Программа" isInline />
          </TextNode>
          <div className="schoolEnrollEndingSpacer-mobileButtons">
            { this.state.noReturnDate &&
              <CountdownMobile enrollEndDate={ this.state.noReturnDate } />
            }
            <SchoolSpacerEnrollButton schoolLink={ this.schoolLink } isGift={ props.isGift } />
          </div>
        </div>
      </div>
    )
  }
}
